

import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import Logout from '../authetication/Logout';
import { useDropdown } from '../DropdownContext/DropdownContext';
import { useLocation, useParams } from 'react-router-dom';
import axiosInstance from '../axiosInstance/axiosInstance';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import PersonIcon from '@mui/icons-material/Person';

import SideBar from '../sidebar/SideBar';
import CourseTabs from '../tabs/CourseTabs';

import { useNavigate } from 'react-router-dom';

import GroupPNG from '../../assets/Group.png';
import { CLIENT_ID, REDIRECT_URI } from '../../config/config';


const CenterWrapper = styled.div`
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  

 

  @media (min-width: 768px) {
    height: 60vh;
  }
  @media (max-width: 426px) {
  background-size: cover;
  height:30vh;
  
  background-position: top center; /* Adjust the focal point for mobile */
   /* Adjust height for mobile devices */
}

 @media (max-width: 482px) {
  background-size: cover;
  height:18vh;
  
  background-position: top center; /* Adjust the focal point for mobile */
   /* Adjust height for mobile devices */
}





@media (max-width: 426px) {
  background-size: cover;

  
  background-position: top center; /* Adjust the focal point for mobile */
   /* Adjust height for mobile devices */
}
  

   @media (max-width: 416px) {
  background-size: cover;
  
  height:19vh;
  
  background-position: top center; /* Adjust the focal point for mobile */
   /* Adjust height for mobile devices */
}
   @media (max-width: 376px) {
  background-size: cover;
  height:22vh;
 
  
  background-position: top center; /* Adjust the focal point for mobile */
   /* Adjust height for mobile devices */
}

  @media (max-width: 345px) {
  background-size: cover;
  height:15vh;
 
  
  background-position: top center; /* Adjust the focal point for mobile */
   /* Adjust height for mobile devices */
}



   
  //   &:before {
  //   content: '';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: rgba(0, 0, 0, 0.4); /* Black overlay with opacity */
  //   z-index: 1;
  // }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0)); /* Stronger gradient */
  pointer-events: none; /* Ensures overlay doesn’t interfere with clicks */
`;

const WhiteBox = styled.div`
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 8px;
 
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  @media (max-width: 768px) {
  left: 35%; /* Center it horizontally on small screens */
   top: 60%;
}
`;

const InputContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%; /* Adjust width as needed */
  max-width: 800px; /* Optional: Limit the width of the input box */
  padding: 0 20px; /* Optional: Add padding for smaller screens */
  z-index: 2; 
`;

const StyledInput = styled.input`
  border: none;
  border-radius: 4px;
  background:white;
  font-size: 12px;
  padding: 8px 16px;
  outline: none;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 3; 
`;
const CenteredContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  padding: 20px;
   
 
`;



const Center = ({ fetchCountries, setSearchQuery, searchQuery, selectedEducationType, setSelectedEducationType }) => {
  const { universityId } = useParams();
  const { isDropdownVisible, setDropdownVisible } = useDropdown();
  const dropdownRef = useRef(null);
  const location = useLocation();
  const [courses, setCourses] = useState([]);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [searchCourseQuery, setsearchCourseQuery] = useState('');
  const countryId = new URLSearchParams(location.search).get('countryId');
  const [educationTypeName, setEducationTypeName] = useState("");

  const [title, setTitle] = useState("Masters");
  useEffect(() => {

    if (selectedEducationType && selectedEducationType.name) {
      setTitle(selectedEducationType.name);

      localStorage.setItem('selectedEducationType', JSON.stringify(selectedEducationType));
    } else {
      setTitle("Masters");
    }
  }, [selectedEducationType]);



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setDropdownVisible]);

  const [universityDetails, setUniversityDetails] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState('/home.png');
  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth < 1024) {
  //       setBackgroundImage(universityDetails?.banner_image || '/img5.png');
  //     } else {
  //       setBackgroundImage(universityDetails?.banner_image || '/home.png');
  //     }
  //   };


  //   handleResize();

  //   window.addEventListener('resize', handleResize);

  //   return () => window.removeEventListener('resize', handleResize);
  // }, [universityDetails]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 501) {
        // Small screens
        setBackgroundImage(universityDetails?.banner_image || '/mobileimage.png');
      } else {
        // Larger screens
        setBackgroundImage(universityDetails?.banner_image || '/home.png');
      }
    };

    // Call on component mount to set the initial image
    handleResize();

    // Add resize event listener
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [universityDetails]);

  useEffect(() => {
    const fetchUniversityDetails = async () => {
      try {
        const response = await axiosInstance.get(`home/api/v1/student-university-detail/${universityId}/`);
        setUniversityDetails(response.data);
      } catch (err) {
        console.error("Error fetching university details:", err.message);
      }
    };

    if (universityId) {
      fetchUniversityDetails();
    }
  }, [universityId]);



  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  const navigate = useNavigate();

  const handleLogoClick = () => {
    localStorage.removeItem('selectedEducationType');
    navigate('/');
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('access');
    const refreshToken = localStorage.getItem('refresh');
    const storedUserInfo = localStorage.getItem('user_info');

    if (accessToken && refreshToken && storedUserInfo) {
      setUserInfo(JSON.parse(storedUserInfo));
    }
  }, []);

    const clientId = '709840142800-eqorq7htnsgb4ahrv707a6i5q7m8ul30.apps.googleusercontent.com';
    const redirectUri = 'https://takemeabroad.me/auth/google/callback';
  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };



  const handleLogin = () => {
    const authUrl = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=email%20profile&service=lso&o2v=2&ddm=0&flowName=GeneralOAuthFlow`;
    window.location.href = authUrl;
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };



  const fetchCourses = (url) => {

    let requestUrl = `${url}`;



    // Make the API request
    axiosInstance.get(requestUrl)
      .then(response => {

        setCourses(response.data.results); // Set the courses from response
        setNextPage(response.data.next); // Set the next page URL
        setPreviousPage(response.data.previous); // Set the previous page URL
      })
      .catch(error => {
        console.error('Error fetching courses:', error);
      });
  };

  useEffect(() => {
    if (countryId) {
      fetchCourses(`home/api/v1/student-course-list/?country=${countryId}&limit=4`);
    }
  }, [countryId, searchCourseQuery,]);


  return (
    <CenterWrapper backgroundImage={backgroundImage}>
      {location.pathname.startsWith("/university") && <Overlay />}
      <div className='relative' style={{
        // background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0))',
        // padding: '4px 0',
        zIndex: 2,
      }}>
        <div className=''>
          <div className="flex items-center justify-between py-2.5 px-6 lg:px-20 xl:28">
            {/* <div className="flex flex-row gap-2 pointer-cursor" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
              <div className="w-[18px] sticky  overflow-hidden">
                <img
                  className="w-full h-full object-contain"
                  src={GroupPNG}
                  alt="logo"
                />
              </div>
              <div className="font-bold  text-lg md:text-xl sticky text-white  ">norvel</div>
            </div> */}
            {/* <div className="flex gap-4 items-center hidden lg:flex relative z-10">
              {userInfo ? (
                <div className="flex gap-2 items-center cursor-pointer" onClick={toggleDropdown}>
                  <img
                    src={userInfo.user_info.profile_img || './default-profile.png'}
                    alt="Profile"
                    className="rounded-full w-6 h-6"
                  />
                  <KeyboardArrowDownOutlinedIcon style={{ color: 'gray', fontSize: '16' }} />
                </div>
              ) : (
                <div className="flex gap-2  items-center bg-white rounded-full px-4 py-1 cursor-pointer" onClick={handleLogin}>
                  <PersonIcon style={{ color: 'black', fontSize: window.innerWidth <= 768 ? '14px' : '16px' }} />

                  <div className=' text-[11px] md:text-[13px] pt-sans-bold text-black '>Login</div>
                </div>
              )}

            </div> */}
            {isSidebarVisible && <SideBar isVisible={isSidebarVisible} onClose={toggleSidebar} />}
          </div>
          {/* <div className='flex justify-center items-center'>
          <input
          type="text"
          className="border border-none rounded-sm bg-[#EBEBEB] text-[12px] pt-sans-regular px-4 pr-4 py-2 outline-none w-full"
          placeholder="Search a Country"
          value={searchQuery}
          onChange={handleSearchChange}
        />

          </div> */}

        </div>



      </div>



      <div className="px-4 md:px-0 py-10 md:py-0">
        <div className="px-2 md:px-0 md:py-0">
          {isDropdownVisible && (
            <div ref={dropdownRef} className="absolute top-2 md:top-6 right-4  md:right-8 px-2 md:px-6 bg-white border rounded shadow-lg z-10">
              <div className="py-1 md:py-1  cursor-pointer text-[13px] pt-sans-regular"><Logout /></div>
            </div>
          )}

          {location.pathname.startsWith("/university") && (
            <WhiteBox>
              <div className='flex gap-4'>
                <div>
                  <div className='flex flex-col'>
                    <div className='flex gap-2   items-center'>
                      <div>
                        <img
                          src={universityDetails?.logo_image}
                          className=" w-[90px] h-[60px] lg:w-[100px]  lg:h-[100px] object-cover"
                        />
                      </div>
                      <div>
                        <div className=' text-lg  md:text-xl pt-sans-bold text-white'>
                          {universityDetails?.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </WhiteBox>
          )}
        </div>

        {/* {location.pathname === "/country" || location.pathname === "/" || location.pathname === "/home" && (
          <InputContainer>
            <p className=' text-xl md:text-xl lg:text-2xl py-2 text-white pt-sans-bold text-center'>{title}  Study programs</p>

            <StyledInput
              type="text"
              placeholder="Search a Country"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </InputContainer>
        )} */}


        {/* {location.pathname === "/course" && (
          <InputContainer>
            <p className=' text-xl md:text-xl lg:text-2xl py-2 text-white pt-sans-bold text-center'>{title}  Study programs</p>
            <StyledInput

              type="text"

              placeholder="Search a Course"
              value={searchCourseQuery} // Bind the input value to the searchQuery state
              onChange={(e) => setsearchCourseQuery(e.target.value)}


            />

          </ InputContainer>
        )} */}
      </div>
    </CenterWrapper>
  );
};

export default Center;


