
import Filters from '../filters/Filters';
import axiosInstance from '../axiosInstance/axiosInstance';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import CourseFilter from '../tabs/CourseFilter';
import { motion, AnimatePresence } from 'framer-motion';
import AgencyFilter from '../filters/AgencyFilter';
import FilterTab from '../filters/FilterTab';





const UniversityCourse = ({universitiesCount, setSelectedEducationType, selectedEducationType, setEducationTypeCounts, educationTypeCounts, fetchCourses, nextPage, previousPage, countryId, courses,count }) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTab, setSelectedTab] = useState(null);

    const navigate = useNavigate();
    const [courseCount, setCourseCount] = useState(0);
    const [universityCount, setUniversityCount] = useState(0);
    const { universityId } = useParams();
    const location = useLocation();
    const [courseDetails, setCourseDetails] = useState(null);
    const [isAgencyFilterVisible, setIsAgencyFilterVisible] = useState(false);

    const [selectedCourseId, setSelectedCourseId] = useState(null);
    const [selectedAgencyName, setSelectedAgencyName] = useState(null)

    const { countryData } = location.state || {};


    const urlParams = new URLSearchParams(location.search);
    const marks = urlParams.get('marks');
    const feesFrom = urlParams.get('feesFrom');
    const feesTo = urlParams.get('feesTo');

    useEffect(() => {
        console.log('Country ID:', countryId);
        console.log('Marks:', marks);
        console.log('Fees From:', feesFrom);
        console.log('Fees To:', feesTo);
    }, [countryId, marks, feesFrom, feesTo]);
    console.log(countryId, marks, feesFrom, feesTo, "hello countrydatadhanya");









    const fetchCourseDetails = async (id) => {
        try {
            const response = await axiosInstance.get(`home/api/v1/student-course-detail/${id}/`);
            setCourseDetails(response.data);
        } catch (err) {
            console.error("Error fetching course details:", err.message);
        }
    };

    // Extract countryId from query params
    const fetchUniversities = (url = `home/api/v1/student-university-list/?limit=4&country=${countryId}`) => {
        axiosInstance.get(url)
            .then(response => {
                setUniversityCount(response.data.count);
                console.log(response.data.count, "hello university count");

            })
            .catch(error => {
                console.error("There was an error fetching the university data!", error);
            });
    };
    const fetchCourse = (url = `home/api/v1/student-course-list/?limit=4&country=${countryId}`) => {
        axiosInstance.get(url)
            .then(response => {
                setCourseCount(response.data.count);

            })
            .catch(error => {
                console.error('Error fetching courses:', error);
            });
    };
    useEffect(() => {
        if (countryId) {
            fetchUniversities();
            fetchCourse();
        }
    }, [countryId, marks, feesFrom, feesTo]);




    const handleUniversityClick = () => {
        // Construct the query string for the University page
        const queryParams = new URLSearchParams({
            countryId,
            marks: marks || '',  // Pass empty string if marks is undefined
            feesFrom: feesFrom || '',
            feesTo: feesTo || ''
        }).toString();

        navigate(`/universities?${queryParams}`, { state: { marks, feesFrom, feesTo } });
    };

    // const handleCourseClick = () => {
    //     // Construct the query string for the Course page
    //     const queryParams = new URLSearchParams({
    //         countryId,
    //         marks: marks || '',
    //         feesFrom: feesFrom || '',
    //         feesTo: feesTo || ''
    //     }).toString();

    //     navigate(`/course?${queryParams}`, { state: { marks, feesFrom, feesTo } });
    // };

    const handleCourseClick = (course) => {
        if (selectedCourseId === course.id) {
            setSelectedCourseId(null); // Hide the slide if the same course is clicked again
        } else {
            setSelectedCourseId(course.id); // Show the slide for the clicked course
            fetchCourseDetails(course.id);
        }
    };








    // const fetchCourses = (url = `home/api/v1/student-course-list/?university=${universityId}&limit=4`) => {
    //     // Build the URL, including education_type only if selectedEducationType.id exists
    //     let requestUrl = url;

    //     if (selectedEducationType && selectedEducationType.id) {
    //         requestUrl += `&education_type=${selectedEducationType.id}`;
    //     }

    //     axiosInstance.get(requestUrl)
    //         .then(response => {
    //             setCount(response.data.count);
    //             setCourses(response.data.results); // Set courses data
    //             setNextPage(response.data.next); // Set next page URL
    //             setPreviousPage(response.data.previous); // Set previous page URL
    //         })
    //         .catch(error => {
    //             console.error('Error fetching courses:', error);
    //         });
    // };

    // // Fetch courses whenever universityId or selectedEducationType changes
    // useEffect(() => {
    //     if (universityId) {
    //         fetchCourses(); // Calls fetchCourses with the current universityId and selectedEducationType
    //     }
    // }, [universityId, selectedEducationType]); // Dependencies for re-fetching data
    // // Trigger re-fetch when universityId or selectedEducationType changes

    const handleNextPage = () => {
        if (nextPage) {
            fetchCourses(nextPage);
            setCurrentPage(currentPage + 1); // Increment page count
        }
    };

    const handlePreviousPage = () => {
        if (previousPage) {
            fetchCourses(previousPage);
            setCurrentPage(currentPage - 1); // Decrement page count
        }
    };
    const handleContactAgency = (agencyFirstName) => {
        setSelectedAgencyName(agencyFirstName); // Store agency name
        setIsAgencyFilterVisible(true); // Show the modal
    };


    const closeModal = () => {
        setIsAgencyFilterVisible(false); // Close the modal
    };








    return (
        <div>
            <div>
                <div className=' md:px-14'>
                    <div className='flex  gap-6 md:py-8 flex-col lg:flex-row'>

                        <div className='flex flex-col '>
                            <div className='hidden md:block'>
                                <CourseFilter setSelectedEducationType={setSelectedEducationType} selectedEducationType={selectedEducationType} setEducationTypeCounts={setEducationTypeCounts} educationTypeCounts={educationTypeCounts} />
                            </div>

                            <div className='px-4 md:px-0'>
                                <Filters marks={marks}
                                    feesFrom={feesFrom}
                                    feesTo={feesTo}
                                    fetchCourses={fetchCourses}
                                    countryId={countryId} />
                            </div>

                        </div>
                        <div className='flex flex-col gap-6 w-full pt-6 px-4 md:px-0'>
                            {/* <div> <div className='flex gap-6'>
                                <div
                                    className='text-[13px] pt-sans-regular cursor-pointer underline' onClick={handleCourseClick}

                                >
                                    {courseCount} Courses
                                </div>
                                <div
                                    className='text-[13px] pt-sans-regular cursor-pointer underline' onClick={handleUniversityClick}

                                >
                                    {universityCount}  University
                                </div>


                            </div></div> */}
                            <FilterTab  count={count} universitiesCount={universitiesCount}/>



                            <div className="grid grid-row-1 md:grid-row-1 lg:grid-row-2 gap-4   ">


                                {courses.length === 0 ? (
                                    <div className="text-center pt-sans-regular text-gray-500 py-4">
                                        No courses available.
                                    </div>
                                ) : (

                                    courses.map(course => (
                                        <div key={course.id} className="bg-white shadow-md cursor-pointer hover:: shadow-lg hover:shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105 "  >
                                            <div className="flex flex-col justify-between gap-4 px-4 py-4">
                                                <div className="flex flex-col md:flex-row justify-between">

                                                    <div className='w-full'>
                                                        <div style={{
                                                             backgroundImage: 'url(/web2.png)', 
                                                            // backgroundImage: `url(${course.university.background_image})`,
                                                            backgroundSize: 'cover', 
                                                            backgroundPosition: 'center', 
                                                            height: '150px',
                                                        }} className='rounded-md'>
                                                            <div className="flex  justify-between item-center">
                                                                <div className='flex items-center py-4 px-4 gap-4'>
                                                                    <img src={course.university.logo_image} alt={`${course.university.name} Logo`} className="w-12 h-12 object-cover rounded-full" /> {/* Adjust width and height as needed */}
                                                                    <div>


                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className='text-[12px] pt-sans-bold pb-1 pt-4 '>{course.university?.name}<span className='text-[12px] pt-sans-bold '>- Private</span></div>

                                                        <div className='pt-sans-regular text-[10px] '>{course.campus}, {course.country?.name}</div>
                                                        <div className=' py-4  flex flex-col md:items-center md:flex-row  justify-between '>
                                                            <div className='flex flex-col pb-2'>
                                                                <div className='text-[13px] pt-sans-bold text-green-600
                                                             '> Admission open</div>
                                                                <div className='pt-sans-bold text-xl'>{course.name}</div>
                                                            </div>

                                                            <div className='bg-green-400 py-1.5 md:py-0 md:h-8 px-4  rounded-full flex justify-center items-center  gap-1  cursor-pointer  ' onClick={() => handleContactAgency(course.agency?.user_info?.first_name)} >

                                                                <div className='text-[11px] whitespace-nowrap  inter-semilight'> Contact Agency</div>
                                                            </div>
                                                            {/* {course.
                                                                is_norvel_recommended &&
                                                                <div className=' hidden md:block pt-sans-bold text-[10px] bg-green-500 rounded-sm px-1'> Recommended</div>} */}
                                                        </div>
                                                        <div class="border-dotted-b   flex items-center justify-center block "></div>
                                                        <div className='grid grid-cols-3 gap-4 pt-2 sm:grid-cols-3 md:grid-cols-5'>
                                                            <div>
                                                                <div className='pt-sans-regular text-[12px]'> Duration</div>
                                                                <div className='pt-sans-bold text-[12px]'>{course.course_duration} Years </div>



                                                            </div>
                                                            <div>
                                                                <div className='pt-sans-regular text-[12px]'>POF</div>
                                                                <div className='pt-sans-bold text-[12px] '>{course.POF}Lakhs({course.POF_mode}) </div>
                                                            </div>
                                                            <div>
                                                                <div className='pt-sans-regular text-[12px]'>Fees</div>
                                                                <div className='pt-sans-bold text-[12px]'>INR {course.fee_per_year}LPA </div>
                                                            </div>
                                                            <div>
                                                                <div className='pt-sans-regular text-[12px]'>Language</div>
                                                                <div className='pt-sans-bold text-[12px]'><span className='flex gap-1 items-center pt-sans-bold'>
                                                                    {/* Check if language_requirements is an array before mapping */}
                                                                    {Array.isArray(course.language_requirements) && course.language_requirements.length > 0 ? (
                                                                        <>
                                                                            {course.language_requirements.map((requirement, index) => (
                                                                                <div className='flex' key={index}>
                                                                                    <span className='pt-sans-bold text-[11px] flex'>
                                                                                        {/* Language requirement and score */}
                                                                                        {requirement.language_requirement}
                                                                                        {/* Only display '-' and score if the requirement is not 'MOI' */}
                                                                                        {requirement.language_requirement !== 'MOI' && ` - ${requirement.score}`}
                                                                                        {/* Add '/' unless it is the last item */}
                                                                                        {index < course.language_requirements.length - 1 && ' / '}
                                                                                    </span>
                                                                                </div>
                                                                            ))}
                                                                            {/* Add "required" after the entire list */}
                                                                            {/* <span className="pt-sans-bold text-[11px] flex"> required</span> */}
                                                                        </>
                                                                    ) : (
                                                                        'N/A' // Fallback if language_requirements is empty or undefined
                                                                    )}
                                                                </span></div>
                                                            </div>
                                                            <div>
                                                                <div className='pt-sans-regular text-[12px]'>Fees</div>
                                                                <div className='pt-sans-bold text-[12px]'>INR {course.fee_per_year}LPA </div>
                                                            </div>
                                                            <div>
                                                                <div className='pt-sans-regular text-[12px]'>Intake</div>
                                                                <div className='pt-sans-bold text-[12px]'> {course.intake} </div>
                                                            </div>






                                                        </div>



                                                        {/* <div className='pt-sans-regular text-[12px] flex gap-1 flex-wrap'>
                                                            {course.education_level} / {course.course_duration} Years /
                                                            <span className='pt-sans-bold'> {course.fee_per_year} Lakhs per year* / </span>
                                                            <span className='flex gap-1 items-center'>
                                                               
                                                                {Array.isArray(course.language_requirements) && course.language_requirements.length > 0 ? (
                                                                    <>
                                                                        {course.language_requirements.map((requirement, index) => (
                                                                            <div className='flex' key={index}>
                                                                                <span className='pt-sans-regular text-[11px] flex'>
                                                                                    
                                                                                    {requirement.language_requirement}
                                                                                    
                                                                                    {requirement.language_requirement !== 'MOI' && ` - ${requirement.score}`}
                                                                                   
                                                                                    {index < course.language_requirements.length - 1 && ' / '}
                                                                                </span>
                                                                            </div>
                                                                        ))}
                                                                      
                                                                        <span className="pt-sans-regular text-[11px] flex"> required</span>
                                                                    </>
                                                                ) : (
                                                                    'N/A' 
                                                                )}
                                                            </span>

                                                        </div> */}





                                                    </div>
                                                    {/* <div className='pt-4   md:block  hidden'>
                                                        <div>
                                                            <div className='pt-sans-bold text-[11px]'>Living Expense : <span className='pt-sans-regular'>{course.POF}Lakhs* / Year</span> </div>
                                                            <div className='text-[10px]'>{course.POF_mode}</div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                {/* <div class="border-dotted-b   flex items-center justify-center block md:hidden"></div> */}
                                                {/* <div className='hidden md:block'>
                                                    <div className="flex  justify-between item-center">
                                                        <div className='flex items-center gap-4'>
                                                            <img src={course.university.logo_image} alt={`${course.university.name} Logo`} className="w-12 h-12 object-cover rounded-full" /> 
                                                            <div>
                                                                <div className='text-[12px] pt-sans-bold '>{course.university?.name}</div>
                                                                <div className=' hidden md:flex gap-2 ' >

                                                                    <div className='text-[12px]'><span className='pt-sans-bold'>private</span></div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='hidden md:block'>
                                                            <div className="flex gap-1 hidden items-center md:block">
                                                                <div className='pt-sans-bold text-[12px]'>{course.campus} , {course.country?.name}</div>
                                                                <img src={course.country.flag} alt={`${course.country.name} Logo`} className="w-8 h-8 object-contain rounded-full" /> 

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div class="hidden md:flex border-dotted-b  items-center justify-center "></div>
                                                <div className="flex justify-between ">
                                                    <div className="flex gap-2.5 flex-wrap">
                                                        {course.tags.map(tag => (
                                                            <div key={tag.id}>
                                                                <span className='inter-bold text-[10px] border  border-black px-4 rounded-full py-2'>{tag.tag}</span>
                                                            </div>
                                                        ))}
                                                    </div>


                                                </div>





                                              

                                                {/* <div

                                                    className=" "
                                                >
                                                    <div className='flex flex-col md:flex-row justify-between gap-10 bg-white px-4'>



                                                        <div className='md:w-full  '>
                                                            <div className=''>
                                                                <span className='pt-sans-regular  text-[11px] '>For Admission and Enquiry</span></div>

                                                            <div className='flex flex-row items-center  justify-between  '>
                                                                <div className='flex gap-2 items-center py-4'>
                                                                    <div>
                                                                        <img
                                                                            src={courses?.agency?.profile_img}
                                                                            alt={`${course?.agency?.user_info?.first_name}'s Logo`}
                                                                            className="w-11 h-11 object-cover rounded-full" 
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <div className='inter-bold text-[12px]'>  {course?.agency?.user_info?.first_name}</div>
                                                                        <div className='text-[12px] pt-sans-regular'>Location : <span className='inter-bold text-[10px] '>{course?.agency?.location}</span></div>
                                                                    </div>
                                                                </div>
                                                                <div className='flex  pb-4'>
                                                                    <div className='bg-green-400 h-6 px-4  rounded-full flex justify-center items-center  gap-1  cursor-pointer  ' onClick={handleContactAgency} >

                                                                        <div className='text-[11px] whitespace-nowrap  inter-semilight'> Contact Agency</div>
                                                                    </div>
                                                                </div>



                                                            </div>



                                                        </div>
                                                    </div>
                                                </div>  */}
                                            </div>





                                        </div>

                                    ))

                                )}


                            </div>


                            {courses.length > 0 &&
                                <div className='flex gap-2 py-6 items-center justify-center'>
                                    {previousPage && (
                                        <button
                                            onClick={handlePreviousPage}
                                            disabled={!previousPage}
                                            className={`text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold ${!previousPage ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        >
                                            Back
                                        </button>
                                    )}


                                    <div className='text-black text-[12px] pt-sans-bold'>{currentPage}</div>
                                    {nextPage && (
                                        <button
                                            onClick={handleNextPage}
                                            disabled={!nextPage}
                                            className={`text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold ${!nextPage ? 'opacity-50 cursor-not-allowed' : ''}`}
                                        >
                                            Next
                                        </button>

                                    )}


                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <AnimatePresence>
                {isAgencyFilterVisible && (
                    <AgencyFilter closeModal={closeModal} countryData={countryData} courseId={selectedCourseId} marks={marks} selectedAgencyName={selectedAgencyName}
                        feesFrom={feesFrom}
                        feesTo={feesTo} />
                )}
            </AnimatePresence>

        </div>
    )
}

export default UniversityCourse