


import React, { useState, useEffect } from 'react';
import CourseFilter from './CourseFilter';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import axiosInstance from '../axiosInstance/axiosInstance';

import { AnimatePresence } from 'framer-motion';
import FilterModal from '../filters/FilterModal';
import Filters from '../filters/Filters';
import { useLocation } from 'react-router-dom'; // Import useLocation
import { Tooltip } from 'react-tooltip';


const Tabs = ({ setSearchQuery, count, searchQuery, selectedEducationType, setSelectedEducationType, educationTypeCounts, setEducationTypeCounts, fetchCountries, newMarks,
    newFeesFrom,
    newFeesTo }) => {

    const location = useLocation();


    const [showSlider, setShowSlider] = useState(false);
    const [secondSlider, setSecondSlider] = useState(false);
    const [title, setTitle] = useState("Masters");
    const [isClosing, setIsClosing] = useState(false);
    const [feesClosing, setFeesClosing] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [searchCourseQuery, setsearchCourseQuery] = useState('');
    const [courses, setCourses] = useState([]);


    const [nextPage, setNextPage] = useState(null);
    const [previousPage, setPreviousPage] = useState(null);
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };
    const country = location.state?.countryData;
    const countryName = country?.name
    console.log(country, "hello data");


    const countryId = new URLSearchParams(location.search).get('countryId');





    // State for slider values

    const [formData, setFormData] = useState({
        marks: 50,
        // fees_from: 0,
        fees_to: 10,

    });
    const toggleFilterModal = () => {
        setShowModal(!showModal); // Toggle modal visibility
    };



    const toggleSlider = () => {
        if (showSlider) {
            setIsClosing(true); // Start the closing animation
            setTimeout(() => {
                setShowSlider(false); // Hide slider after animation completes
                setIsClosing(false); // Reset closing state
            }, 300); // Match the transition duration in CSS
        } else {
            setShowSlider(true); // Open slider immediately
        }
    };

    // const toggleSlider = () => {
    //     setShowSlider(prev => !prev);
    // };
    // const toggleSecondSlider = () => {
    //     setSecondSlider(prev => !prev);
    // };
    const toggleSecondSlider = () => {
        if (secondSlider) {
            setFeesClosing(true); // Start the closing animation
            setTimeout(() => {
                setSecondSlider(false); // Hide slider after animation completes
                setFeesClosing(false); // Reset closing state
            }, 300); // Match the transition duration in CSS
        } else {
            setSecondSlider(true); // Open slider immediately
        }

    }



    const handleSliderChange = (event, newValue) => {
        setFormData(prev => ({ ...prev, marks: newValue }));


    };
    const handleSliderCommittedChange = (event, newValue) => {
        setFormData(prev => ({ ...prev, marks: newValue }));
        setIsClosing(true);
        setTimeout(() => {
            setShowSlider(false); // Hide slider after animation completes
            setIsClosing(false); // Reset closing state
        }, 300); // Match the transition duration in CSS
    };


    // const handleFeesChange = (event, newValue) => {
    //     setFormData(prev => ({
    //         ...prev,
    //         fees_from: newValue[0],
    //         fees_to: newValue[1]
    //     }));

    // };


    const handleFeesChange = (event, newValue) => {
        setFormData((prev) => ({
            ...prev,
            fees_to: newValue,
        }));
    };
    const handleFeesCommittedChange = (event, newValue) => {
        setFormData((prev) => ({
            ...prev,
            fees_to: newValue,
        }));

        setFeesClosing(true);
        setTimeout(() => {
            setSecondSlider(false);
            setFeesClosing(false);
        }, 300);
    };



    const formatFees = (value) => `${value} LPA`; // Example formatting function
    useEffect(() => {
        // Check if `selectedEducationType` exists and has a name
        if (selectedEducationType && selectedEducationType.name) {
            setTitle(selectedEducationType.name); // Update title with the selected education type's name
            // Save the selectedEducationType to local storage for persistence
            localStorage.setItem('selectedEducationType', JSON.stringify(selectedEducationType));
        } else {
            setTitle("Masters"); // Default title if no selectedEducationType
        }
    }, [selectedEducationType]); // Add `selectedEducationType` as a dependency



    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            fetchCountries(null, formData.marks, formData.fees_from, formData.fees_to);
        }, 500); // Call fetchCountries 500ms after the last slider change

        return () => clearTimeout(debounceTimeout); // Cleanup previous timeout on change
    }, [formData]); // Trigger only when formData changes
    const fetchCourses = (url) => {

        let requestUrl = `${url}`;



        // Make the API request
        axiosInstance.get(requestUrl)
            .then(response => {

                setCourses(response.data.results); // Set the courses from response
                setNextPage(response.data.next); // Set the next page URL
                setPreviousPage(response.data.previous); // Set the previous page URL
            })
            .catch(error => {
                console.error('Error fetching courses:', error);
            });
    };


    useEffect(() => {
        if (countryId) {
            fetchCourses(`home/api/v1/student-course-list/?country=${countryId}&limit=4`);
        }
    }, [countryId, searchCourseQuery,]);


    useEffect(() => {
        const modalShown = sessionStorage.getItem('modalShown');
        if (!modalShown) {
            setTimeout(() => {
                setShowModal(true); 
                console.log("show modal ");
                
                sessionStorage.setItem('modalShown', 'true');
            }, 30000);
        }
    }, []);



    return (
        <div>
            <div>

                <div className=" px-4 md:px-20">
                    <div className="flex flex-col ">
                        <div className=' py-4 md:py-6 flex flex-col '>
                        {!countryName &&<div className=' block md:hidden text-[18px] lg:text-[28px] pt-sans-bold font-bold'> Find {title}  courses   </div>}
                            {countryName &&
                                <div className=' block md:hidden text-[18px] lg:text-[28px] pt-sans-bold font-bold text-center'> {title}  Study Programs   {countryName && ` in  ${countryName}`} </div>}
                                 <div className=' hidden md:block text-[18px] lg:text-[28px] pt-sans-bold font-bold text-center'> {title}  Study Programs   {countryName && ` in  ${countryName}`} </div>
                            <div className='hidden md:block'>
                                <CourseFilter
                                    setSelectedEducationType={setSelectedEducationType}
                                    selectedEducationType={selectedEducationType}
                                    setEducationTypeCounts={setEducationTypeCounts}
                                    educationTypeCounts={educationTypeCounts}
                                />
                            </div>
                        </div>
                        {location.pathname == '/' && (
                            <div className="flex items-center border border-gray-400 rounded-full px-6 py-4">
                                {/* Search Input */}
                                <div className="mr-2" >
                                    <img src="/search.png" alt="Search Icon" className="w-[22px] h-[22px] md:w-4 md:h-4" />
                                </div>
                                <input
                                    type="text"
                                    className="flex-grow text-[16px] md:text-[14px] pt-sans-regular outline-none"
                                    placeholder="Search a Country"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />

                                {/* Search Icon */}


                                {/* Filter Icon */}
                                <div className="ml-2" onClick={toggleFilterModal}>
                                    <img src="/filter.png" alt="Filter Icon" className="w-[25px] h-[25px] md:w-4 md:h-4" />
                                </div>
                            </div>)

                        }
                        {location.pathname == '/home' && (
                            <div className="flex items-center border border-gray-400 rounded-full px-4 py-2.5">
                                {/* Search Input */}
                                <div className="mr-2" >
                                    <img src="/search.png" alt="Search Icon" className="w-[22px] h-[22px] md:w-4 md:h-4" />
                                </div>
                                <input
                                    type="search"
                                    className="flex-grow text-[16px] md:text-[14px] pt-sans-regular outline-none"
                                    placeholder="Search a Country"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />

                                {/* Search Icon */}


                                {/* Filter Icon */}
                                <div className="ml-2 cursor-pointer" data-tooltip-id="apply-filter" data-tooltip-content="Apply Filter" onClick={toggleFilterModal}>
                                    <img src="/filter.png" alt="Filter Icon" className="w-[25px] h-[25px] md:w-4 md:h-4" />
                                </div>
                                <Tooltip id="apply-filter" place="top" />
                            </div>)

                        }




                        {/* course  */}
                        {location.pathname === "/course" && (
                            <div className="flex items-center border border-gray-400 rounded-full px-4 py-2.5">
                                {/* Search Input */}
                                <div className="mr-2" >
                                    <img src="/search.png" alt="Search Icon" className="w-[22px] h-[22px] md:w-4 md:h-4" />
                                </div>
                                <input
                                    type="search"
                                    className="flex-grow text-[16px] md:text-[14px] pt-sans-regular outline-none"
                                    placeholder="Search a Course"
                                    value={searchCourseQuery} // Bind the input value to the searchQuery state
                                    onChange={(e) => setsearchCourseQuery(e.target.value)}
                                />

                                {/* Search Icon */}


                                {/* Filter Icon */}
                                <div className="ml-2 cursor-pointer" data-tooltip-id="apply-filter" data-tooltip-content="Apply Filter" onClick={toggleFilterModal}>
                                    <img src="/filter.png" alt="Filter Icon" className="w-[25px] h-[25px] md:w-4 md:h-4" />
                                </div>

                                <Tooltip id="apply-filter" place="top" />
                            </div>

                        )}








                        {/* <div className='flex flex-col justify-center  gap-4 pt-4 md:flex-row py-2 px-2'>
                            <div className='flex flex-col w-full justify-center gap-4  md:gap-10 md:flex-row'>
                                {/* <div className='flex flex-col lg:flex-row gap-4  '>
                                    <div
                                        className={`h-10 border border-gray-100 rounded-sm py-2 px-4 shadow-md flex justify-between items-center rounded-md cursor-pointer ${showSlider ? 'bg-green-200' : 'bg-white'
                                            }`}
                                        onClick={toggleSlider}
                                    >
                                        <div className='flex justify-between w-full gap-16 px-4'>
                                            <div className='pt-sans-bold text-[11px] lg:text-[12px]'>Your Marks in Class</div>
                                            <div className='pt-sans-bold text-[12px]'>{formData.marks}%</div>
                                        </div>
                                        <div>{showSlider ? <FiChevronUp /> : <FiChevronDown />}</div>
                                    </div>
                                    {showSlider && (
                                        <div className={` lg:w-[220px] slider-container ${isClosing ? 'closed' : ''}`}>
                                            <Box>
                                                <Slider
                                                    value={formData.marks}
                                                    onChange={handleSliderChange}
                                                    onChangeCommitted={handleSliderCommittedChange}
                                                    min={0}
                                                    max={100}
                                                    valueLabelDisplay="auto"

                                                    sx={{
                                                        color: 'black',
                                                        '& .MuiSlider-thumb': {
                                                            borderRadius: '50%',
                                                            backgroundColor: 'black',
                                                        },
                                                        '& .MuiSlider-track': {
                                                            backgroundColor: 'black',
                                                        },
                                                        '& .MuiSlider-rail': {
                                                            backgroundColor: '#bfbfbf',
                                                        },
                                                        '& .MuiSlider-valueLabel': {
                                                            backgroundColor: 'black',
                                                            color: 'white',
                                                            borderRadius: '4px',
                                                        },
                                                    }}
                                                />
                                                <div className='flex justify-between'>
                                                    <div className='pt-sans-regular text-[12px]'>0%</div>
                                                    <div className='pt-sans-bold text-[12px]'>{formData.marks}%</div>
                                                    <div className='pt-sans-regular text-[12px]'>100%</div>
                                                </div>
                                            </Box>
                                        </div>

                                    )}

                                </div>



                                <div className='flex flex-col lg:flex-row gap-4   '>
                                    <div className={`h-10 border border-gray-100 rounded-sm py-2 px-4 shadow-md flex justify-between items-center rounded-md cursor-pointer ${secondSlider ? 'bg-green-200' : 'bg-white'
                                        }`} onClick={toggleSecondSlider}>
                                        <div className='flex justify-between w-full gap-6 px-4'>
                                            <div className='pt-sans-bold text-[11px] lg:text-[12px]'>Budget For Education</div>
                                            <div className='pt-sans-bold text-[12px]'>Up to {formData.fees_to} LPA</div>
                                        </div>
                                        <div><FiChevronDown /></div>
                                    </div>
                                    {secondSlider && (
                                        <div className={` lg:w-[220px] slider-container ${feesClosing ? 'closed' : ''}`}>
                                            <Box>
                                                <Slider
                                                    value={formData.fees_to}
                                                    onChange={handleFeesChange}
                                                    valueLabelDisplay="auto"
                                                    onChangeCommitted={handleFeesCommittedChange}
                                                    min={0}
                                                    max={50}
                                                    sx={{
                                                        color: 'black',
                                                        '& .MuiSlider-thumb': {
                                                            borderRadius: '50%',
                                                            backgroundColor: 'black',
                                                        },
                                                        '& .MuiSlider-track': {
                                                            backgroundColor: 'black',
                                                        },
                                                        '& .MuiSlider-rail': {
                                                            backgroundColor: 'black',
                                                        },
                                                        '& .MuiSlider-valueLabel': {
                                                            backgroundColor: 'black',
                                                            color: 'white',
                                                            borderRadius: '4px',
                                                        },
                                                    }}
                                                />
                                            </Box>
                                            <div className='flex justify-between '>
                                                <div className='pt-sans-regular text-[12px]'>0 LPA</div>
                                                <div className='pt-sans-bold text-[12px]'>{` ${formatFees(formData.fees_to)}`}</div>
                                                <div className='pt-sans-regular text-[12px]'>50 LPA</div>
                                            </div>
                                        </div>

                                    )}


                                </div> */}



                        {/* </div>


                        </div> */}

                        <div className='flex flex-col justify-center  '>
                            {location.pathname === "/" && (
                                <div className='text-black pt-sans-bold text-[16px] py-4 '>
                                    {/* {count} Countries Are Now Open for Admissions */}
                                    Major Study Destinations
                                </div>
                            )}
                            {location.pathname === "/home" && (
                                <div className='text-black pt-sans-bold text-[16px] py-4 '>
                                    {/* {count} Countries Are Now Open for Admissions */}
                                    Major Study Destinations
                                </div>
                            )}



                            {location.pathname === "/course" && (
                                <div className='text-black pt-sans-bold text-[16px] py-4 '>
                                    {/* {count} Countries Are Now Open for Admissions */}
                                    Open Programs
                                </div>

                            )}

                        </div>

                    </div>
                </div>
            </div>
            <AnimatePresence>
                {
                    showModal && (
                        <FilterModal setShowModal={setShowModal} fetchCourses={fetchCourses} fetchCountries={fetchCountries} marks={newMarks} feesTo={newFeesTo} />

                    )
                }
            </AnimatePresence>
        </div>
    );
};

export default Tabs;
