import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from '../axiosInstance/axiosInstance';



const FilterTab = ({selectedEducationType,setSelectedEducationType, universitiesCount,count}) => {
    const [courseCount, setCourseCount] = useState(0)
    const [universityCount, setUniversityCount] = useState(0)
    const [selectedTab, setSelectedTab] = useState(null);
   
    const navigate = useNavigate();
    const location = useLocation();
    const { countryData} = location.state || {};
     // Extract filter values from URL parameters
     const urlParams = new URLSearchParams(location.search);
     const marks = urlParams.get('marks');
    //  const feesFrom = urlParams.get('feesFrom');
     const feesTo = urlParams.get('feesTo');
     const countryId = urlParams.get('countryId');
 
     console.log(marks,  feesTo,countryId, "hello hai how are you");



    // const countryId = new URLSearchParams(location.search).get('countryId');

    // Update filter values on location change
   

    const fetchUniversities = (url = `home/api/v1/student-university-list/?limit=4&country=${countryId}`) => {
        axiosInstance.get(url)
            .then(response => {
                setUniversityCount(response.data.count);
            })
            .catch(error => {
                console.error("There was an error fetching the university data!", error);
            });
    };
    useEffect(() => {
        // Fetch universities when the component mounts or countryId changes
        if (countryId) {
            fetchUniversities();
        }
    }, [countryId]);

    const fetchCourses = (url = `home/api/v1/student-course-list/?limit=4&country=${countryId}`) => {
        axiosInstance.get(url)
            .then(response => {
                setCourseCount(response.data.count);
            })
            .catch(error => {
                console.error('Error fetching courses:', error);
            });
    };
    useEffect(() => {
        // Fetch courses when the component mounts or countryId changes
        if (countryId) {
            fetchCourses();
        }
    }, [countryId]);
    // const handleTabChange = (tab) => {
    //     setSelectedTab(tab);
    //     if (tab === 'courses') {
    //         navigate(`/course?countryId=${countryId}`, { state: { countryData } });
    //     } else if (tab === 'university') {
    //         // Navigate to /universities with the countryId as a query param
    //         navigate(`/universities?countryId=${countryId}`, { state: { countryData } });
    //     }
    // };


    // const handleTabChange = (tab) => {
    //     setSelectedTab(tab);

    //     // Construct query parameters
    //     const queryParams = new URLSearchParams({
    //         countryId,
    //         marks: marks || "",  // Add marks even if empty
    //         feesFrom: feesFrom || "",  // Add feesFrom even if empty
    //         feesTo: feesTo || ""  // Add feesTo even if empty
    //     });

    //     if (tab === 'courses') {
    //         navigate(`/course?${queryParams.toString()}`, { state: { countryData, marks, feesFrom, feesTo } });
    //     } else if (tab === 'university') {
    //         navigate(`/universities?${queryParams.toString()}`, { state: { countryData, marks, feesFrom, feesTo } });
    //     }
    // };


    const handleTabChange = (tab) => {
        setSelectedTab(tab);

        // Construct query parameters based on current URL parameters
        const queryParams = new URLSearchParams({
            countryId,
            marks: marks || "",  // Add marks even if empty
            // feesFrom: feesFrom || "",  // Add feesFrom even if empty
            feesTo: feesTo || ""  // Add feesTo even if empty
        });

        if (tab === 'courses') {
            navigate(`/course?${queryParams.toString()}`, { state: { countryData } });
        } else if (tab === 'university') {
            navigate(`/universities?${queryParams.toString()}`, { state: { countryData } });
        }
    };




    return (
        <div> <div className='flex gap-6'>
            <div
                className={`text-[13px] pt-sans-regular cursor-pointer underline ${location.pathname === '/course' ? 'pt-sans-bold' : ''}`}
                onClick={() => handleTabChange('courses')} // Trigger tab change and navigation
            >
                 {count} Courses
            </div>
            <div
                className={`text-[13px] pt-sans-regular cursor-pointer underline ${location.pathname === '/universities' ? 'pt-sans-bold' : ''}`}
                onClick={() => handleTabChange('university')} // Navigate to /universities when clicked
            >
                {universitiesCount}  University
            </div>


        </div></div>
    )
}

export default FilterTab
