import React, { useState, useEffect } from 'react'
import Filters from '../filters/Filters';
import CourseFilter from '../tabs/CourseFilter';
import axiosInstance from '../axiosInstance/axiosInstance';
import { useNavigate, useLocation } from 'react-router-dom';
import FilterTab from '../filters/FilterTab';




const Universities = ({universitiesCount,count, setSelectedEducationType, selectedEducationType, setEducationTypeCounts, educationTypeCounts, searchQuery,fetchUniversities,countryId,prevPage,nextPage,universities,marks,feesFrom,feesTo }) => {
   
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTab, setSelectedTab] = useState(null);
   
    const navigate = useNavigate();
    const location = useLocation();


    // const countryId = new URLSearchParams(location.search).get('countryId');

    // const countryData = location.state?.countryData || null;


    // const fetchUniversities = (url = `home/api/v1/student-university-list/?country=${countryId}&limit=4`) => {
    //     // Create a base URL
    //     let requestUrl = `${url}`;

    //     // Check if selectedEducationType and its id are defined
    //     if (selectedEducationType && selectedEducationType.id) {
    //         requestUrl += `&education_type=${selectedEducationType.id}`;
    //     }

    //     // Add the search query if it exists
    //     if (searchQuery) {
    //         requestUrl += `&search=${searchQuery}`;
    //     }

    //     axiosInstance.get(requestUrl)
    //         .then(response => {
    //             setCount(response.data.count);
    //             setUniversities(response.data.results); // Set universities data
    //             setNextPage(response.data.next); // Set next page URL
    //             setPrevPage(response.data.previous); // Set previous page URL
    //         })
    //         .catch(error => {
    //             console.error("There was an error fetching the university data!", error);
    //         });
    // };

   
    // useEffect(() => {
    //     if (countryId) {
    //         fetchUniversities(); 
    //     }
    // }, [countryId, selectedEducationType, searchQuery]); 
    


    const handleNext = () => {
        if (nextPage) {
            fetchUniversities(nextPage);
            setCurrentPage((prev) => prev + 1);
        }
    };

    // Handle "Previous" button click
    const handlePrevious = () => {
        if (prevPage) {
            fetchUniversities(prevPage);
            setCurrentPage((prev) => prev - 1);
        }
    };
    // const handleUniversityClick = (university) => {
    //     navigate(`/university/${university.id}`, { state: { countryData, university } }); // Pass university in state
    // };

    // const handleUniversityClick = (university) => {
    //     console.log("Navigating with values:", {    countryId, marks, feesFrom, feesTo }); // Log values before navigation
    
    //     navigate(`/university/${university.id}`, {
    //         state: {
    //             countryId,
    //             university,
    //             marks,
    //             feesFrom,
    //             feesTo
    //         }
    //     });
    // };


    const handleUniversityClick = (university) => {
        // Log values before navigation for debugging
        console.log("Navigating with values:", { countryId, marks, feesFrom, feesTo });
    
        // Use URLSearchParams to build the query string
        const queryParams = new URLSearchParams({
            countryId,
            marks,
            feesFrom,
            feesTo
        }).toString();
    
        // Navigate to the university page with query parameters and additional state
        navigate(`/university/${university.id}?${queryParams}`, {
            state: {
                countryId,
                university,
                marks,
                feesFrom,
                feesTo
            }
        });
    };
    
    
    



    return (
        <div>
            <div>
                <div>
                    <div className=' md:px-16 '>

                        <div className='flex  w-full gap-6 md:py-2 flex-col lg:flex-row '>
                            <div className='flex flex-col '>
                                <div className='hidden md:block'>
                                    <CourseFilter setSelectedEducationType={setSelectedEducationType} selectedEducationType={selectedEducationType} setEducationTypeCounts={setEducationTypeCounts} educationTypeCounts={educationTypeCounts} />
                                </div>

                                <div className='px-4 md:px-0'>
                                <Filters marks={marks}
                                    feesFrom={feesFrom}
                                    feesTo={feesTo}
                                    fetchUniversities={fetchUniversities}
                                    countryId={countryId} />
                                </div>

                            </div>
                            <div className='flex flex-col gap-6 w-full pt-6 px-4 md:px-0 '>

                                <FilterTab  count={count}  universitiesCount={ universitiesCount}/>
                                <div className="grid grid-row-1 md:grid-row-1 lg:grid-row-2 gap-4  gap-4 ">
                                    {universities.length === 0 ? (
                                        <div className="text-center text-gray-500 p-4 pt-sans-regular">No universities available.</div>
                                    ) : (



                                        universities.map((university) => (
                                            // <div key={university.id} className="bg-white shadow-md   " onClick={() => handleUniversityClick(university.id)} >
                                            <div key={university.id} className="bg-white shadow-md  cursor-pointer hover:: shadow-lg hover:shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105  " onClick={() => handleUniversityClick(university)} >
                                                <div className="flex flex-col justify-between gap-4 px-4 py-4">
                                                    <div>
                                                        <div className=" flex flex-col md:flex-row  justify-between item-center">
                                                            <div className='flex gap-4'>
                                                                <img src={university.logo_image} alt="University Logo" className="w-12 h-12 rounded-full object-contain" />
                                                                <div>
                                                                    <div className='flex items-center gap-2'>
                                                                        <div className='text-[13px] pt-sans-bold '>{university.name}</div>
                                                                        {university.is_norvel_recommended && (
                                                                            <div className="pt-sans-bold text-[10px] bg-green-500 rounded-sm px-1 whitespace-nowrap hidden md:block">
                                                                                Recommended
                                                                            </div>
                                                                        )}
                                                                    </div>

                                                                    <div className='flex gap-2 items-center flex-wrap '>
                                                                        <div className='text-[12px]'>Fees Starts at:<span className='text-[10px] pt-sans-bold '>{university.minimum_fee_per_year} Lakhs per Year* /</span>

                                                                        </div>



                                                                        {university.language_requirements && university.language_requirements.length > 0 && (
                                                                            <div className='text-[11px] mt-1 pt-sans-regular'>
                                                                                {/* Map through the language requirements */}
                                                                                {university.language_requirements.map((lang, idx) => (
                                                                                    <span key={lang.id} >
                                                                                        {lang.name}
                                                                                        {idx < university.language_requirements.length - 1 ? " / " : ""}
                                                                                    </span>
                                                                                ))}
                                                                            </div>
                                                                        )}
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div className=' hidden md:flex '>
                                                                <div className="flex gap-1 items-center">
                                                                    <div className='pt-sans-bold text-[11px]'>{university.country?.name}</div>
                                                                    <div>

                                                                        <img src={university.country?.flag} className="w-8 h-8 rounded-full" />
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='md:hidden '>
                                                        <div class="border-dotted-b   flex items-center justify-center block md:hidden"></div>
                                                        <div className='flex justify-between pt-4'>
                                                            <div>
                                                                <div className='text-[12px]'>Location:</div>
                                                                <div className='text-[12px] pt-sans-bold'>{university.location}</div>
                                                            </div>

                                                            <div className="flex gap-1 items-center">
                                                                <div className='pt-sans-bold text-[11px]'>{university.country?.name}</div>
                                                                <div>
                                                                    <img src={university.country?.flag} className="w-8 h-8 rounded-full" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>





                                                    <div class="border-dotted-b   flex items-center justify-center block md:hidden"></div>
                                                    <div className='flex '>
                                                    </div>

                                                    <div className='flex justify-between'>
                                                        <div className='hidden md:block' >
                                                            <div className='text-[12px]'>Location:</div>
                                                            <div className='text-[12px] pt-sans-bold  '>{university.location}</div>
                                                        </div>

                                                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8">
                                                            <div className='px-6 flex gap-2 items-center'>
                                                                <div>
                                                                    <img src="./bachelors.png" className="w-5 h-5 md:w-6 md:h-6" />

                                                                </div>
                                                                <div>
                                                                    <div className='text-[13px] pt-sans-regular'>Bachelor's</div>
                                                                    <div className='pt-sans-bold text-[12px]'>{university.education_levels_count.Bachelors}</div>
                                                                </div>
                                                            </div>
                                                            <div className='px-6 flex gap-2 items-center'>
                                                                <div>
                                                                    <img src="./masters.png" className="w-5 h-5 md:w-6 md:h-6" />

                                                                </div>
                                                                <div>
                                                                    <div className='text-[13px] pt-sans-regular'>Masters'</div>
                                                                    <div className='pt-sans-bold text-[12px]'>{university.education_levels_count.Masters}</div>
                                                                </div>
                                                            </div>
                                                            <div className='px-6 flex gap-2 items-center'>
                                                                <div>
                                                                    <img src="./scholarships.png" className="w-5 h-5 md:w-6 md:h-6" />

                                                                </div>
                                                                <div>
                                                                    <div className='text-[13px] pt-sans-regular'>Scholarship</div>
                                                                    <div className='pt-sans-bold text-[12px]'>{university.scholarship}</div>
                                                                </div>
                                                            </div>
                                                            <div className='px-6 flex gap-2 items-center'>
                                                                <div>
                                                                    <img src="./type.png" className=' className="w-5 h-5 md:w-6 md:h-6" ' />

                                                                </div>
                                                                <div>
                                                                    <div className='text-[13px] pt-sans-regular'>Type</div>
                                                                    <div className='pt-sans-bold text-[12px]'>{university.university_type}</div>
                                                                </div>
                                                            </div>
                                                            <div className='px-6 flex gap-2 items-center'>
                                                                <div>
                                                                    <img src="./rank.png" className="w-5 h-5 md:w-6 md:h-6" />

                                                                </div>
                                                                <div>
                                                                    <div className='text-[13px] pt-sans-regular'>Global Ranking</div>
                                                                    <div className='pt-sans-bold text-[12px]'>{university.rank}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                        )))}
                                </div>
                            </div>
                        </div>
                        {universities.length > 0 && (

                            <div className="flex gap-2 py-6 items-center justify-center">
                                {prevPage && (
                                    <button
                                        className="text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold"
                                        onClick={handlePrevious}
                                    >
                                        Back
                                    </button>
                                )}
                                <div className="text-black text-[12px] pt-sans-bold">{currentPage}</div>
                                {nextPage && (
                                    <button
                                        className={`text-[10px] text-black bg-green-500 px-4 py-2 rounded-full pt-sans-bold ${!nextPage && 'opacity-50'}`}

                                        onClick={handleNext}
                                    >
                                        NEXT
                                    </button>

                                )}

                            </div>
                        )}

                    </div>


                </div>






            </div>





        </div>
    )
}

export default Universities


