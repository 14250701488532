import React, { useEffect, useState } from 'react';
import Header from '../components/header/Header'
import Center from '../components/center/Center'

import Footer from '../components/footer/Footer'
import UniversityDetailView from '../components/courses/UniversityDetailView'
import UniversityCourse from '../components/courses/universityCourse'
import { DropdownProvider } from '../components/DropdownContext/DropdownContext'
import { useLocation, useParams } from 'react-router-dom';
import axiosInstance from '../components/axiosInstance/axiosInstance'


export const UniversityDetails = () => {
  const [admissionCloseDay, setAdmissionCloseDay] = useState(null);
  const [courses, setCourses] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [universities, setUniversities] = useState([]);
  const [count, setCount] = useState(0)
  const [nextsPage, setNextsPage] = useState(null);
    const [prevPage, setPrevPage] = useState(null);
    const [universitiesCount, setUniversitiesCount] = useState(0)

  // const location = useLocation();
  // const { course } = location.state || {};
  // const courseId = course?.id;
  

 



  // const { countryId, university, marks, feesFrom, feesTo } = location.state || {};
  
  // const universityId = university?.id;

  // useEffect(() => {
  //     console.log("Received data:", { marks, feesFrom, feesTo }); // Log received values
  // }, [marks, feesFrom, feesTo]);
  const { universityId } = useParams();



  const location = useLocation();
  
  // Get parameters from URL
  const urlParams = new URLSearchParams(location.search);
  const countryId = urlParams.get('countryId');
  const marks = urlParams.get('marks');
  // const feesFrom = urlParams.get('feesFrom');
  const feesTo = urlParams.get('feesTo');

  const { course } = location.state || {};
  const courseId = course?.id;
  // const universityId = course?.university?.id;

  useEffect(() => {
    console.log("Received data from URL params:", { marks, feesTo });
  }, [marks,  feesTo]);

  









  useEffect(() => {
    const fetchAdmissionCloseDay = async () => {
      try {
        const response = await axiosInstance.get(`home/api/v1/student-university-detail/${courseId}/`);
        setAdmissionCloseDay(response.data.admission_close_day);
      } catch (error) {
        console.error('Error fetching admission close day:', error);
      }
    };

    if (courseId) {
      fetchAdmissionCloseDay();
    }
  }, [courseId]);
  const [educationTypeCounts, setEducationTypeCounts] = useState([]);
  const [selectedEducationType, setSelectedEducationType] = useState('');

  const fetchCountries = async (url, educationType = '') => {

    try {
      const response = await axiosInstance.get(`${url}`);
      setEducationTypeCounts(response.data.education_type_count);
    } catch (error) {
      console.error('Error fetching country data:', error);
    }
  };

  // useEffect(() => {
  //     fetchCountries(`home/api/v1/country-list/?limit=9&`, selectedEducationType);
  // }, [ selectedEducationType]);

  useEffect(() => {
    fetchCountries(`home/api/v1/country-list/?limit=9&`);
    const storedEducationType = localStorage.getItem('selectedEducationType');
    if (storedEducationType) {
      try {
        setSelectedEducationType(JSON.parse(storedEducationType));
      } catch (error) {
        console.error('Error parsing selectedEducationType from localStorage:', error);
        localStorage.removeItem('selectedEducationType');
      }
    }
  }, []);

  useEffect(() => {
    if (selectedEducationType) {
      localStorage.setItem('selectedEducationType', JSON.stringify(selectedEducationType));
    } else {
      localStorage.removeItem('selectedEducationType');
    }
  }, [selectedEducationType]);

  const fetchCourses = (url) => {
    // Build the URL conditionally, including education_type, marks, fees_from, and fees_to
    let requestUrl = `${url}`;

    // Append selectedEducationType.id if it exists
    if (selectedEducationType && selectedEducationType.id) {
      requestUrl += `&education_type=${selectedEducationType.id}`;
    }

    // Append search query if it exists


    // Append marks if it exists
    if (marks) {
      requestUrl += `&marks=${marks}`;
    }

    // Append fees_from if it exists
    // if (feesFrom) {
    //   requestUrl += `&fees_from=${feesFrom}`;
    // }

    // Append fees_to if it exists
    if (feesTo) {
      requestUrl += `&fees_to=${feesTo}`;
    }

   
    axiosInstance.get(requestUrl)
      .then(response => {
        setCount(response.data.count);
        setCourses(response.data.results); // Set the courses from response
        setNextPage(response.data.next); // Set the next page URL
        setPreviousPage(response.data.previous); // Set the previous page URL
      })
      .catch(error => {
        console.error('Error fetching courses:', error);
      });
  };

  useEffect(() => {
    if (countryId || universityId)  {
      fetchCourses(`home/api/v1/student-course-list/?country=${countryId}&university=${universityId}&limit=4`);
    }
  }, [countryId, selectedEducationType, marks, feesTo]);






  const fetchUniversities =  (url) => {
    let requestUrl = `${url}`;
  

    if (selectedEducationType && selectedEducationType.id) {
        requestUrl += `&education_type=${selectedEducationType.id}`;
    }

    if (searchQuery) {
        requestUrl += `&search=${searchQuery}`;
    }

    if (marks) {
        requestUrl += `&marks=${marks}`;
    }
    // if (feesFrom) {
    //     requestUrl += `&fees_from=${feesFrom}`;
    // }
    if (feesTo) {
        requestUrl += `&fees_to=${feesTo}`;
    }

   axiosInstance.get(requestUrl)
   .then(response=>{
    setUniversitiesCount(response.data.count);
    setUniversities(response.data.results);
    setNextsPage(response.data.next);
    setPrevPage(response.data.previous);

   })
       
    . catch (error=>{
        console.error("There was an error fetching the university data!", error);

    }) 
};

useEffect(() => {
    if (countryId) {
        fetchUniversities(`home/api/v1/student-university-list/?country=${countryId}&limit=4`);
    }
   
}, [countryId, selectedEducationType, searchQuery, marks,  feesTo]);



  return (
    <div>
      <DropdownProvider>
        <div className='bg-gray-100 '>
          <Header/>
       






          <Center universityId={universityId} />
          <div className='bg-green-400 flex items-center justify-center  pt-sans-bold '>
            <span className="py-1 text-[13px]">
              Admission closes in {admissionCloseDay !== null ? `${admissionCloseDay} days` : '...loading'}
            </span>
          </div>

          <UniversityDetailView />
        </div>
        <div >
          <UniversityCourse educationTypeCounts={educationTypeCounts} setEducationTypeCounts={setEducationTypeCounts} selectedEducationType={selectedEducationType} setSelectedEducationType={setSelectedEducationType}

            marks={marks}
            // feesFrom={feesFrom}
            feesTo={feesTo}
            courses={courses}
            count={count}
            previousPage={previousPage}
            nextPage={nextPage}
            countryId={countryId}
            fetchCourses={fetchCourses}
            universitiesCount={universitiesCount}
            />

        </div>

        <div>
          <Footer />
        </div>

      </DropdownProvider>
    </div>
  )
}
