import React, { useEffect, useState, useCallback } from 'react';
import Header from '../components/header/Header'
import Center from '../components/center/Center'

import Footer from '../components/footer/Footer'
import axiosInstance from '../components/axiosInstance/axiosInstance';

import Countries from '../components/countries/Countries'
import CourseFilter from '../components/tabs/CourseFilter';
import Tabs from '../components/tabs/Tabs';
import { ITEMS_PER_PAGE } from '../config/config';
import { DropdownProvider } from '../components/DropdownContext/DropdownContext';

const Home = () => {
  const [countries, setCountries] = useState([]);

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [count, setCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [educationTypeCounts, setEducationTypeCounts] = useState([]);
  const [selectedEducationType, setSelectedEducationType] = useState('');
  const [marks, setMarks] = useState(0);
  const [feesFrom, setFeesFrom] = useState(0);
  const [feesTo, setFeesTo] = useState(10);




  // const fetchCountries = async (url) => {
  //   setLoading(true);
  //   try {
  //     // Construct the URL with education type if provided
  //     const educationType = selectedEducationType ? selectedEducationType.id : '';
  //     const response = await axiosInstance.get(`${url}&education_type=${educationType}`);

  //     setCountries(response.data.results);
  //     setNextPage(response.data.next);
  //     setPreviousPage(response.data.previous);
  //     setCount(response.data.count);
  //     setTotalPages(Math.ceil(response.data.count / ITEMS_PER_PAGE));
  //     setEducationTypeCounts(response.data.education_type_count);
  //   } catch (error) {
  //     console.error('Error fetching country data:', error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchCountries(`home/api/v1/country-list/?limit=9&search=${searchQuery}`);
  // }, [searchQuery, selectedEducationType]);






  const constructUrl = (searchQuery, selectedEducationType, marks, feesFrom, feesTo) => {
    const educationType = selectedEducationType ? selectedEducationType.id : '1';

    // Set feesFrom and feesTo to null if they are not defined (0 or 10 are used as defaults)
    const feesFromValue = feesFrom !== undefined && feesFrom !== 0 ? feesFrom : null;
    const feesToValue = feesTo !== undefined && feesTo !== 10 ? feesTo : null;

    return `home/api/v1/country-list/?limit=9&search=${searchQuery}&education_type=${educationType}&marks=${marks}&fees_to=${feesToValue}`;
  };


  const fetchCountries = async () => {
    setLoading(true);
    try {
      const url = constructUrl(searchQuery, selectedEducationType, marks, feesFrom, feesTo);
      const response = await axiosInstance.get(url);

      setCountries(response.data.results);
      setNextPage(response.data.next);
      setPreviousPage(response.data.previous);
      setCount(response.data.count);
      setTotalPages(Math.ceil(response.data.count / ITEMS_PER_PAGE));
      setEducationTypeCounts(response.data.education_type_count);
    } catch (error) {
      console.error('Error fetching country data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCountries(); // No need to pass URL here, it will be constructed internally
  }, [searchQuery, selectedEducationType, marks, feesFrom, feesTo]);



  useEffect(() => {

    const storedEducationType = localStorage.getItem('selectedEducationType');
    if (storedEducationType) {
      try {
        setSelectedEducationType(JSON.parse(storedEducationType)); // Parse only if it's valid
      } catch (error) {
        console.error('Error parsing selectedEducationType from localStorage:', error);
        localStorage.removeItem('selectedEducationType'); // Remove invalid item
      }
    }
  }, []);

  useEffect(() => {
    // Save selected education type to local storage whenever it changes
    if (selectedEducationType) {
      localStorage.setItem('selectedEducationType', JSON.stringify(selectedEducationType)); // Ensure to stringify
    } else {
      localStorage.removeItem('selectedEducationType'); // Clear if none selected
    }
  }, [selectedEducationType]);





  return (
    <div>
      <DropdownProvider>

        <div

        >
          <Header />
          <div className='block md:hidden'>
            <CourseFilter
              setSelectedEducationType={setSelectedEducationType}
              selectedEducationType={selectedEducationType}
              setEducationTypeCounts={setEducationTypeCounts}
              educationTypeCounts={educationTypeCounts}
            />
          </div>

          <Center fetchCountries={fetchCountries} searchQuery={searchQuery} setSearchQuery={setSearchQuery} setSelectedEducationType={setSelectedEducationType} selectedEducationType={selectedEducationType} />

        </div>
        <Tabs searchQuery={searchQuery} count={count} setSearchQuery={setSearchQuery} setEducationTypeCounts={setEducationTypeCounts} educationTypeCounts={educationTypeCounts} setSelectedEducationType={setSelectedEducationType} selectedEducationType={selectedEducationType} fetchCountries={(url, newMarks, newFeesFrom, newFeesTo) => {
          setMarks(newMarks);
          setFeesFrom(newFeesFrom);
          setFeesTo(newFeesTo);
          fetchCountries(url, newMarks, newFeesFrom, newFeesTo);
        }}
        />


        <div>
          <Countries totalPages={totalPages} previousPage={previousPage} setCurrentPage={setCurrentPage} nextPage={nextPage} currentPage={currentPage} countries={countries} fetchCountries={fetchCountries} count={count} setEducationTypeCounts={setEducationTypeCounts} educationTypeCounts={educationTypeCounts} setSelectedEducationType={setSelectedEducationType} selectedEducationType={selectedEducationType} loading={loading} marks={marks} feesFrom={feesFrom} feesTo={feesTo} />

        </div>

        <div className=''>
          <Footer />
        </div>
      </DropdownProvider>





    </div>
  )
}

export default Home
