import React from 'react'
import Header from '../header/Header'
import AdmissionPartner from './AdmissionPartner'
import Footer from '../footer/Footer'
import { DropdownProvider } from '../DropdownContext/DropdownContext'

const AgencyPartner = () => {
    return (
        <div>
            <DropdownProvider>

                <div>
                    <Header />

                </div>
                <div className='md:py-6'>
                    <AdmissionPartner />
                </div>
                <div><Footer /></div>
            </DropdownProvider>





        </div>
    )
}

export default AgencyPartner