

import React, { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import axiosInstance from '../axiosInstance/axiosInstance';

const UniversityDetailView = () => {
    const [universityDetails, setuniversityDetails] = useState(null);

    const [isAgencyFilterVisible, setIsAgencyFilterVisible] = useState(false);
    const location = useLocation();
    const { countryData, university } = location.state || {};
    const { course } = location.state || {};

    const courseId = course?.id;
    const universityId = university?.id;





    const handleContactAgency = () => {
        setIsAgencyFilterVisible(true); // Show the modal
    };

    const closeModal = () => {
        setIsAgencyFilterVisible(false); // Close the modal
    };

    useEffect(() => {
        if (universityId) {
            // Directly use the passed university object
            setuniversityDetails(university);
            // Optional: If you need to fetch additional details
            const fetchUniversityDetails = async () => {
                try {
                    const response = await axiosInstance.get(`home/api/v1/student-university-detail/${universityId}/`);
                    setuniversityDetails(response.data); // Use fetched data if required
                } catch (err) {
                    console.error("Error fetching university details:", err.message);
                }
            };
            // Uncomment if fetching from API is needed
            fetchUniversityDetails();
        }
    }, [universityId]);

    return (
        <div className=''>
            <div className='flex flex-col md:flex-row justify-between gap-10 bg-white px-4 md:px-10'>
                <div className='md:w-full'>
                    <div className='md:pt-16 pb-2 pt-2'><span className='text-[14px] pt-sans-bold '>Description</span></div>
                    <div class="border-dotted-b   flex items-center justify-center"></div>
                    <div className='text-[12px] pt-sans-regular py-6 '>
                        {universityDetails?.description}




                    </div>
                </div>


            </div>


        </div>
    )
}

export default UniversityDetailView