import React from 'react'
import { motion } from 'framer-motion';
import CongratesPNG from '../../assets/congratulationPopup.png';
import { useNavigate } from 'react-router-dom';

const CongratulationModal = ({ agencyName }) => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate('/');
    };
    return (
        <div>
            <motion.div className="fixed inset-0 bg-black bg-opacity-70" />
            <motion.div className="p-6 z-10 fixed inset-0 flex justify-center items-center">
                <div className=' px-4 py-6 md:px-20 bg-white shadow-lg md:py-20'>
                    <div className='flex flex-col text-center px-10 justify-center items-center '>
                        <div className=' w-16 h-16 md:w-24 md:h-24' >
                            <img
                                className="w-full h-full object-contain"
                                src={CongratesPNG}
                                alt="logo"
                            />


                        </div>
                        <div className=' text-md md:text-lg pt-sans-bold pb-2'>Congratulations</div>
                        <div className='text-[13px] pt-sans-regular'>Your contact details have been Shared successfully.</div>
                        {agencyName && (
                            <div className='text-[13px] pt-sans-regular '> <span className='underline'>“{agencyName}”</span>.Will contact you in 48 Hrs</div>

                        )}
                    </div>
                    <div className="py-2 ">
                        <button
                            onClick={handleGoBack}

                            className="bg-green-400 text-black rounded-full pt-sans-bold  py-2 w-full text-[12px]"
                        >
                            Go Back
                        </button>
                    </div>

                    {/* <div className='text-[13px] pt-sans-regular text-center'>Meantime you can contact the consultant directly on
                    </div> */}
                </div>




            </motion.div>
        </div>

    )
}

export default CongratulationModal